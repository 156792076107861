<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">上报内容</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton1"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <div class="flex-between">
                    <div class="itemPart">
                        <el-form-item
                            label="对象分类"
                            prop="YCYSFL_1"
                            :style="{ width: '80%' }"
                        >
                            <el-select
                                v-model="formdata.dataDic.YCYSFL_1"
                                placeholder="请选择"
                                :disabled="!isEdit"
                                filterable
                                @change="changeDfxl()"
                            >
                                <el-option
                                    v-for="item in ycysTypeList"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.name"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item
                            label="遗产组成"
                            prop="YCYSFL_2"
                            :style="{ width: '80%' }"
                        >
                            <el-select
                                v-model="formdata.dataDic.YCYSFL_2"
                                placeholder="请选择"
                                :disabled="!isEdit"
                                filterable
                                @change="changeYczc()"
                            >
                                <el-option
                                    v-for="item in yczcList"
                                    :key="item.YCYSFL_2"
                                    :label="item.YCYSFL_2"
                                    :value="item.YCYSFL_2"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- App端 -->
                        <el-form-item
                            v-if="formdata.dataDic.LRFS == '2'"
                            label="遗产要素"
                            prop="YCYSBM"
                            :style="{ width: '80%' }"
                        >
                            <el-select
                                v-model="formdata.dataDic.YCYSBM"
                                placeholder="请选择"
                                :disabled="!isEdit"
                                filterable
                            >
                                <el-option
                                    v-for="item in ycysList"
                                    :key="item.BM"
                                    :label="item.MC"
                                    :value="item.BM"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item
                            label="异常发生位置"
                            prop="YCFSWZ"
                            :style="{ width: '80%' }"
                        >
                            <el-input
                                v-model="formdata.dataDic.YCFSWZ"
                                :disabled="!isEdit"
                                placeholder=""
                                type="textarea"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item
                            label="事件描述"
                            prop="SJMS"
                            :style="{ width: '80%' }"
                        >
                            <el-input
                                v-model="formdata.dataDic.SJMS"
                                :disabled="!isEdit"
                                placeholder=""
                                type="textarea"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="上报人电话"
                            prop="SBRDH"
                            :style="{ width: '80%' }"
                        >
                            <el-input
                                v-model="formdata.dataDic.SBRDH"
                                :disabled="!isEdit"
                                placeholder=""
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="上报时间"
                            prop="SBSJ"
                            :style="{ width: '80%' }"
                        >
                            <el-input
                                v-model="formdata.dataDic.SBSJ"
                                :disabled="true"
                                placeholder=""
                            >
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="itemPart">
                        <!-- 小程序 -->
                        <span v-if="formdata.dataDic.LRFS == '4'">
                            <el-form-item
                                label="对象类型"
                                prop="DXLX"
                                :style="{ width: '80%' }"
                            >
                                <el-select
                                    v-model="formdata.dataDic.DXLX"
                                    placeholder="请选择"
                                    filterable
                                    clearable
                                    :disabled="!isEdit"
                                    @change="xcxDxlxChange()"
                                >
                                    <el-option
                                        v-for="(item, index) in sblxList.list"
                                        :key="index"
                                        :label="item.Mc"
                                        :value="item.Id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="上报类型"
                                prop="YCLX"
                                :style="{ width: '80%' }"
                            >
                                <el-select
                                    v-model="formdata.dataDic.YCLX"
                                    placeholder="请选择"
                                    filterable
                                    clearable
                                    :disabled="!isEdit"
                                >
                                    <el-option
                                        v-for="(item, index) in xcxSblxList"
                                        :key="index"
                                        :label="item.Mc"
                                        :value="item.Id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </span>
                        <!-- app -->
                        <span v-else>
                            <el-form-item
                                label="当时采取的措施"
                                prop=""
                                :style="{ width: '80%' }"
                            >
                                <el-input
                                    v-model="formdata.dataDic.DSCQDCS"
                                    :disabled="!isEdit"
                                    placeholder=""
                                    type="textarea"
                                    :rows="3"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item
                                label="上报类型"
                                prop="YCLX"
                                :style="{ width: '80%' }"
                            >
                                <SnTreeselect
                                    v-if="isEdit"
                                    :treeselectData="sblxList"
                                    :treeselectValue="sblxList.selectValue"
                                    @handleChange="sblxChange"
                                    ref="SnTreeselect"
                                ></SnTreeselect>
                                <el-input
                                    v-if="!isEdit"
                                    v-model="formdata.dataDic.YCLXNAME"
                                    :disabled="!isEdit"
                                    placeholder=""
                                >
                                </el-input>
                            </el-form-item>
                        </span>
                        <!-- App端 -->
                        <el-form-item
                            v-if="formdata.dataDic.LRFS == '2'"
                            label="评估"
                            prop="PG"
                            :style="{ width: '80%' }"
                        >
                            <el-select
                                v-model="formdata.dataDic.PG"
                                placeholder="请选择评估"
                                filterable
                                clearable
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(item, index) in pgList"
                                    :key="index"
                                    :label="item.LABEL"
                                    :value="item.CODE"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="上报人"
                            prop="CJRMC"
                            :style="{ width: '80%' }"
                        >
                            <el-input
                                v-model="formdata.dataDic.CJRMC"
                                :disabled="true"
                                placeholder=""
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="是否转为异常"
                            prop="SFZYC"
                            :style="{ width: '80%' }"
                        >
                            <!--   :disabled="!isEdit" -->
                            <el-select
                                v-model="formdata.dataDic.SFZYC"
                                :disabled="true"
                            >
                                <el-option
                                    v-for="(item, index) in sfzwycList"
                                    :key="index"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="上报来源"
                            prop="LRFS"
                            :style="{ width: '80%' }"
                        >
                            <el-select
                                v-model="formdata.dataDic.LRFS"
                                :disabled="true"
                            >
                                <el-option
                                    v-for="(item, index) in sblyList"
                                    :key="index"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="经纬度"
                            prop="jwd"
                            :style="{ width: '80%' }"
                        >
                            <div class="flex">
                                <el-tooltip
                                    class="item"
                                    effect="light"
                                    content="请填写正确的经纬度坐标，经度和纬度用英文的逗号隔开，如：118.056786,24.435336或 118.071978,24.444005；或者点击“坐标拾取”按钮，直接获取经度坐标"
                                    placement="bottom"
                                >
                                    <el-input
                                        v-model="formdata.dataDic.jwd"
                                        placeholder="请输入经纬度"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                        style="width: 442px"
                                    ></el-input>
                                </el-tooltip>
                                <el-button
                                    size="small"
                                    @click="showPickup = true"
                                    >坐标拾取</el-button
                                >
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <el-form-item label="附件" prop="" :style="{ width: '80%' }">
                    <div class="imgPart scrollbar">
                        <SnFileDialog :fileData="fileData"> </SnFileDialog>
                    </div>
                </el-form-item>
            </el-form>
        </div>

        <coordinatePickup
            :editState="isEdit"
            :showPickupP.sync="showPickup"
            :coordinate.sync="formdata.dataDic.jwd"
        ></coordinatePickup>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
import coordinatePickup from "@comp/map/coordinate_pickup";
export default {
    mixins: [infoMixin],
    components: {
        coordinatePickup,
    },
    data() {
        return {
            ycysTypeList: [
                {
                    name: "古茶林",
                },
                {
                    name: "传统村落",
                },
                {
                    name: "分隔防护林",
                },
            ],
            yczcList: [], //遗产组成 列表
            ycysList: [], //遗产要素 列表
            showPickup: false,
            range: "",
            isEdit: false,
            saveButton: true,
            formdata: {
                dataDic: {
                    YCYSFL_1: "", //对象分类
                    YCYSFL_2: "", //遗产组成
                    YCYSBM: "", //遗产要素
                    YCFSWZ: "", //异常发生位置
                    SJMS: "", //事件描述
                    SBRDH: "", //上报人电话
                    SBSJ: "", //上报时间
                    DXLX: "", //对象类型
                    YCLX: "", //上报类型
                    PG_DESC: "", //评估
                    CJRMC: "", //上报人
                    SFZYC: "", //是否转为异常
                    LRFS: "", //上报类型
                    DSCQDCS: "", //当时采取的措施
                    jwd: "",
                    SHZT: "",
                    PG: "",
                },
                PIC: [],
                fileids: "",
                itemCode: "1202",
                metaJson: [],
            },
            rules: {
                // YCYSFL_2: [
                //     {
                //         required: true,
                //         message: "请选择",
                //         trigger: "blur",
                //     },
                // ],
                // YCYSBM: [
                //     {
                //         required: true,
                //         message: "请选择",
                //         trigger: "blur",
                //     },
                // ],
                DXLX: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
                YCLX: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
            },
            pgList: [], //评估列表
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "",
                    name: "",
                    list: [],
                },
            },
            snButton1: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveDataAdmin",
                        name: "保存",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    // {
                    //     isShow: true,
                    //     btnType: "button",
                    //     operateType: "saveNosubmit",
                    //     editType: "2",
                    //     name: "修改",
                    //     round: true,
                    //     backColor: "#6f32ff",
                    //     color: "#fff",
                    // },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "savePass",
                        editType: "2",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveNoPass",
                        editType: "2",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "returnList",
                        editType: "0,1,2",
                        name: "返回",
                        round: true,
                        backColor: "#ff9066",
                        color: "#fff",
                    },
                ],
            },
            sblyList: [], //上报来源
            sblxList: {
                selectValue: "",
                clearable: true,
                searchable: true,
                placeholder: "请选择上报类型",
                class: "", //selectRound
                width: "446px",
                list: [],
                value: "",
                operateType: "treesearch",
                isShow: true,
            }, //上报类型
            sfzwycList: [], //是否转为异常
            xcxSblxList: [], //小程序上报类型列表
        };
    },
    watch: {},
    created() {},
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions([
            "getAllEnumInfobytableID",
            "GetYczcList",
            "GetYcysState",
            "GetRcxcycjlYclxList",
            "GetDxYclx",
        ]),
        GetDataNext() {
            this.formdata.dataDic.SFZYC = String(this.formdata.dataDic.SFZYC);
            this.formdata.dataDic.LRFS = String(this.formdata.dataDic.LRFS);
            this.formdata.dataDic.jwd = `${this.formdata.dataDic.JD},${this.formdata.dataDic.WD}`;
            // app
            if (this.formdata.dataDic.LRFS == "2") {
                this.sblxList.selectValue = this.formdata.dataDic.YCLX;
            }
            this.fileData.mp3List = [];
            this.fileData.mp4List = [];
            this.fileData.imgList = [];
            this.formdata.PIC.map((item) => {
                let dw = item.path.substring(item.path.lastIndexOf(".") + 1);
                switch (dw) {
                    case "mp3":
                        this.fileData.mp3List.push(item.path);
                        break;
                    case "mp4":
                        this.fileData.mp4List.push(item.path);
                        break;
                    case "jpg":
                    case "png":
                    case "jpeg":
                    case "gif":
                    case "tiff":
                        this.fileData.imgList.push(item.path);
                        break;
                    default:
                        this.fileData.othersPart.list.push(item);
                        break;
                }
            });
            this.getYczcList();
            this.getYcysListByYczc();
            // 小程序
            if (this.formdata.dataDic.LRFS == "4") {
                this.getSblxXcxList();
            } else {
                this.getSblxAppList();
                this.sblxList.selectValue = this.formdata.dataDic.YCLX;
            }
        },
        isPower(type) {
            let powerList = localStorage.monitoringPower.split(",");
            return powerList.indexOf(type) > -1;
        },
        // 控制按钮显示
        contralBtn() {
            let inPower = this.isPower("2"); //可审核
            //    type: 0 => 新建; 1 => 编辑; 2 => 查看 3=> 审核
            //    btnType: 0 查看  1 编辑  2 审核
            let btnType =
                this.type === "0" || this.type === "1"
                    ? "1"
                    : this.type === "3"
                    ? "2"
                    : "0";
            //  0 提交未审核/已提交  1 未提交  2 审核通过  3 审核不通过
            this.isEdit =
                this.formdata.dataDic.SHZT != 2 &&
                this.formdata.dataDic.SHZT != 3 &&
                btnType === "2";
            this.snButton1.buttonData.forEach((item) => {
                if (item.name == "保存") {
                    if (this.type === "999") {
                        if (
                            inPower ||
                            localStorage.roleId
                                .split(",")
                                .includes(window.ROLE_ID.CJGLY)
                        ) {
                            this.isEdit = true; //输入框可以编辑
                            item.isShow = true; //详情页面显示 保存按钮
                        } else {
                            item.isShow = false;
                        }
                    } else {
                        this.isEdit = false; //输入框可以编辑
                        item.isShow = false;
                    }
                } else {
                    item.isShow =
                        item.editType.split(",").indexOf(btnType) > -1;
                }
            });
        },
        // 对象分类改变
        changeDfxl() {
            this.formdata.dataDic.YCYSFL_2 = "";
            this.formdata.dataDic.YCYSBM = "";
            this.ycysList = [];
            this.getYczcList();
        },
        // 遗产组成变化
        changeYczc() {
            // App端
            if (this.formdata.dataDic.LRFS == "2") {
                this.formdata.dataDic.YCYSBM = "";
                this.getYcysListByYczc();
            }
        },
        // 获取 遗产组成
        async getYczcList() {
            let result = await this.GetYczcList({
                ycyslx: this.formdata.dataDic.YCYSFL_1,
            });
            this.yczcList = result;
        },
        // 获取 遗产要素 从 遗产组成
        async getYcysListByYczc() {
            let result = await this.GetYcysState({
                keyword: "",
                ycyslx: "",
                yczc: this.formdata.dataDic.YCYSFL_2
                    ? this.formdata.dataDic.YCYSFL_2
                    : "",
            });
            this.ycysList = result.DATA;
        },
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1205",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                switch (v.COLUMNID) {
                    case "LRFS": //上报来源
                        this.sblyList = v.dataItems;
                        break;
                    case "SFZYC": //是否转为异常
                        this.sfzwycList = v.dataItems;
                        break;
                    case "PG": //评估
                        this.pgList = v.dataItems;
                        break;
                }
            });
        },
        // 获取上报类型列表App
        async getSblxAppList() {
            let result = await this.GetRcxcycjlYclxList({});
            result.forEach((item) => {
                item.id = item.ID;
                item.label = item.NAME;
                if (item.Children && item.Children.length > 0) {
                    item.children = item.Children;
                    this.handleChild(item.children);
                }
            });
            this.sblxList.list = result;
        },
        handleChild(val) {
            if (val && val.length > 0) {
                val.forEach((ele) => {
                    ele.id = ele.ID;
                    ele.label = ele.NAME;
                    if (ele.Children && ele.Children.length > 0) {
                        ele.children = ele.Children;
                        this.handleChild(ele.children);
                    }
                });
                return val;
            }
        },
        // 获取上报类型的值
        sblxChange(type, list) {
            this.formdata.dataDic.YCLX = list[0] ? list[0] : "";
        },
        // 小程序 对象类型变化
        xcxDxlxChange() {
            this.formdata.dataDic.YCLX = "";
            this.xcxSblxList = this.common.aryListFindOne(
                this.sblxList.list,
                "Id",
                this.formdata.dataDic.DXLX
            ).Child;
        },
        // 小程序 获取上报类型列表
        async getSblxXcxList() {
            let result = await this.GetDxYclx({});
            this.sblxList.list = result;
            this.xcxSblxList = this.common.aryListFindOne(
                this.sblxList.list,
                "Id",
                this.formdata.dataDic.DXLX
            ).Child;
        },
    },
};
</script>

<style lang="scss" scoped>
.fjPart {
    height: 230px;
    margin-bottom: 30px;
    .fjItem {
        width: 217px;
        height: 230px;
        margin-bottom: 30px;
        margin-right: 30px;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}
.imgPart {
    width: 760px;
    max-height: 300px;
}
</style>
